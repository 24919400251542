import { ReactNode } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import { StyledButton, StyledIcon } from './styles';
export type ShowMoreButtonProps = {
  /**
   * @default 'Show More'
   * */
  label?: string | ReactNode;
  href: string;
  hideArrow?: boolean;
  padding?: string;
  onClick?: () => void;
};
/**
 * ===========================
 * MAIN
 * ===========================
 */
export const ShowMoreButton: React.FC<ShowMoreButtonProps> = (props) => {
  const { label, href, hideArrow = false, padding } = props;
  // ======================= HOOKS
  const { t } = useTranslation('common');
  // ======================= RENDER FUNCTIONS
  const renderLabel = () => {
    if (!label) {
      return (
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            lineHeight: '24px',
            color: '#1E242F',
          }}
        >
          {t('showMore')}
        </p>
      );
    }
    return typeof label === 'string' ? (
      <p style={{ fontSize: 16, fontWeight: 700, lineHeight: '24px' }}>
        {label}
      </p>
    ) : (
      label
    );
  };
  // ======================= VIEWS
  return (
    <Link href={href}>
      <StyledButton
        style={{
          textDecoration: 'none',
          display: 'flex',
          gap: 4,
        }}
        $padding={padding}
      >
        {renderLabel()}{' '}
        {!hideArrow && (
          <StyledIcon className="material-symbols-outlined">
            <span className="material-symbols-outlined">trending_flat</span>
          </StyledIcon>
        )}
      </StyledButton>
    </Link>
  );
};
/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default ShowMoreButton;
