/* eslint-disable @typescript-eslint/no-explicit-any */
import { Key } from 'react';
import { BorderTitle } from '@stack/ui/components';
import Dayjs from 'dayjs';
import { capitalize } from 'lodash';
import useTranslation from 'next-translate/useTranslation';

export type FuelPriceBoxProps = {
  lastUpdatedAt?: string;
  fuels?: any;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const FuelPriceBox: React.FC<FuelPriceBoxProps> = (props) => {
  const { fuels } = props;
  const lastUpdatedAt = fuels?.[0]?.fuel_type?.[0]?.fuel_price_date;

  // ======================= HOOKS
  const { t } = useTranslation('common');

  // ======================= RENDER FUNCTIONS
  const renderPriceDifference = (diff: string) => {
    const newDiff = parseFloat(diff);
    if (newDiff === 0) {
      return;
    }
    if (newDiff < 0) {
      return (
        <p
          className="text-sm"
          style={{
            fontWeight: 500,
            color: '#2BC38D',
            textAlign: 'right',
          }}
        >
          {diff}
        </p>
      );
    }
    return (
      <p
        className="text-sm"
        style={{
          fontWeight: 500,
          color: '#FD643C',
          textAlign: 'right',
        }}
      >
        +{diff}
      </p>
    );
  };

  // ======================= VIEWS
  return (
    <BorderTitle title={t('fuelPrice')}>
      <div
        style={{
          border: '1px solid #EDECED',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            padding: 20,
            display: 'flex',
            gap: 30,
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 20,
              flexDirection: 'column',
            }}
          >
            {fuels &&
              fuels?.map((fuel: any, index: number) => {
                const fuelData = fuel.fuel_type;
                return (
                  <div
                    key={`fuel-${fuel?.fuel_category_name}-${index}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 20,
                    }}
                  >
                    <p
                      className="text-sm"
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {capitalize(fuel.fuel_category_name)}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.75rem',
                      }}
                    >
                      {fuelData?.map((data: any, index: Key) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '2fr 1fr 1fr',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: 8,
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  width: 9,
                                  height: 9,
                                  borderRadius: '50%',
                                  backgroundColor: data.fuel_type_color,
                                }}
                              />
                              <p
                                className="text-sm"
                                style={{
                                  fontWeight: 400,
                                }}
                              >
                                {data.fuel_type_name}
                              </p>
                            </div>
                            <p
                              className="text-sm"
                              style={{
                                fontWeight: 500,
                              }}
                            >
                              {`RM ${data.fuel_price_current}`}
                            </p>
                            {renderPriceDifference(data.fuel_price_changes)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          {lastUpdatedAt && (
            <p
              className="text-sm"
              style={{
                fontWeight: 400,
                color: '#A8B5C9',
              }}
            >
              {`${t('lastUpdated')} ${Dayjs(lastUpdatedAt).format(
                'DD MMM, YYYY'
              )}`}
            </p>
          )}
        </div>
      </div>
    </BorderTitle>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default FuelPriceBox;
