import styled from 'styled-components';

export const StyledBrandGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.5rem;
  row-gap: 1rem;
  align-items: flex-start;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(9, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const FlexBrandGroupBox = styled.div<{
  backgroundColor: string;
  mobilePadding: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    padding: ${({ mobilePadding }) => mobilePadding};
  }
`;

export const FlexBrandGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1024px) {
    display: none;

    gap: 20px;
  }
`;

export const FlexMobileBrandGroupWrapper = styled.div`
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1206px) {
    gap: 1px;
  }

  @media (max-width: 1024px) {
    display: flex;
    gap: 20px;
  }

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

export const StyledBrandItem = styled.div`
  padding: 4px;
`;

export const Title = styled.h2<{ fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 700;
  line-height: 28.5px;
  font-family: 'Inter', sans-serif;
  color: #1e242f;
  margin: 0;
  text-align: center;
  // padding: 1rem;
  // background-color: #f7f7f7;
  // border-bottom: 1px solid #edeced;
`;

export default StyledBrandGroup;
