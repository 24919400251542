/* eslint-disable @typescript-eslint/no-explicit-any */
import { BorderTitle } from '@stack/ui/components';
import Dayjs from 'dayjs';
import useTranslation from 'next-translate/useTranslation';

import ShowMoreButton from '../ShowMoreButton';

export type RunningNumbersBoxProps = {
  lastUpdatedAt?: string;
  runningNumbers?: {
    stateName: string;
    plateNumber: string;
  }[];
  webView?: boolean;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const RunningNumbersBox: React.FC<RunningNumbersBoxProps> = (props) => {
  const { lastUpdatedAt, runningNumbers, webView } = props;

  // ======================= HOOKS
  const { t } = useTranslation('common');

  // ======================= VIEWS
  return (
    <BorderTitle title={t('jpjRunningNumbers')}>
      <div
        style={{
          border: '1px solid #EDECED',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            padding: 20,
            display: 'flex',
            gap: 20,
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.75rem',
            }}
          >
            {runningNumbers?.map((runningNumber: any, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p
                    className="text-sm"
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {runningNumber?.setting_car_plate_name}
                  </p>
                  <p
                    className="text-sm"
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {runningNumber?.setting_car_plate_no}
                  </p>
                </div>
              );
            })}
          </div>
          {lastUpdatedAt && (
            <p
              className="text-sm"
              style={{
                fontWeight: 400,
                color: '#A8B5C9',
              }}
            >
              {`${t('lastUpdated')} ${Dayjs(lastUpdatedAt).format(
                'DD MMM, YYYY'
              )}`}
            </p>
          )}
        </div>
        <div
          style={{
            borderTop: '1px solid #EDECED',
          }}
        >
          <ShowMoreButton
            href={webView ? '/webview/car-plate' : '/car-plate'}
            padding="10px"
          />
        </div>
      </div>
    </BorderTitle>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default RunningNumbersBox;
