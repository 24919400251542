import styled from 'styled-components';

export const StyledButton = styled.div<{
  $padding?: string;
}>`
  display: flex;
  color: inherit;
  justify-content: center;
  align-items: center;
  gap: 4;
  padding: ${(props) => (props.$padding ? props.$padding : '6px 8px')};
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: rgb(247, 247, 247);
  }
`;

export const StyledIcon = styled.span<{ $whiteText?: boolean }>`
  font-size: inherit;
  line-height: 0;
  color: ${(props) => (props.$whiteText ? '#FFF' : '#1E242F')};
  vertical-align: middle;
`;
