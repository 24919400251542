/* eslint-disable @typescript-eslint/no-explicit-any */
import { Key } from 'react';
import StyledImage from '@apps/components/Image';
import { BrandItemType } from '@apps/config/brand.config';
import usePostMsgContext from '@apps/containers/WebviewProvider/hooks';
import createBasePath from '@stack/helpers/lib/createBasePath';
import isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import {
  FlexBrandGroupBox,
  FlexBrandGroupWrapper,
  FlexMobileBrandGroupWrapper,
  Title,
} from './styles';

export type BrandGroupProps = {
  brands: BrandItemType[];
  webView?: boolean;
  title?: string;
  titleSize?: string;
  showTitle?: boolean;
  isNewCar?: boolean;
  backgroundColor?: string;
  imageSize?: number;
  imageContainerSize?: number;
  renderAsPortal?: boolean;
  mobilePadding?: string;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const BrandGroup: React.FC<BrandGroupProps> = (props) => {
  const {
    title,
    titleSize = '19px',
    showTitle = true,
    brands,
    webView = false,
    isNewCar = false,
    backgroundColor = '#f7f7f7',
    imageSize = 44,
    imageContainerSize = 70,
    renderAsPortal = true,
    mobilePadding = '30px 20px 30px 20px',
  } = props;

  // ======================= HOOKS
  const { t } = useTranslation('common');
  const { clickEvent } = usePostMsgContext();
  const { basePath } = createBasePath({
    webView,
    type: isNewCar ? 'cars' : 'news',
  });

  const renderBrandImage = (brand: {
    car_brand_id: any;
    car_brand_slug: any;
    car_brand_image: any;
    car_brand_name: any;
  }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: imageSize,
            height: imageSize,
          }}
        >
          <StyledImage
            src={brand.car_brand_image}
            alt={brand.car_brand_name}
            width={imageSize}
            height={imageSize}
          />
        </div>
        <p
          style={{
            margin: 0,
            marginTop: 5,
            fontSize: 12,
            lineHeight: '15.6px',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '-0.25px',
            textDecoration: 'none',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 70,
            textOverflow: 'ellipsis',
          }}
        >
          {brand.car_brand_name}
        </p>
      </div>
    );
  };

  const renderBrandItem = (brand: {
    car_brand_id: any;
    car_brand_slug: any;
    car_brand_image: any;
    car_brand_name: any;
  }) => {
    const href = !isNewCar
      ? `${basePath}/latest/${brand.car_brand_slug}`
      : `${basePath}/${brand.car_brand_slug}`;

    const onClick =
      !isNewCar || !webView
        ? undefined
        : clickEvent(href, true, {
            title: brand.car_brand_name,
            params: {
              brand: brand.car_brand_slug,
            },
          });
    return (
      <div
        key={brand.car_brand_id}
        style={{
          width: imageContainerSize,
          height: imageContainerSize,
          maxWidth: imageContainerSize,
          maxHeight: imageContainerSize,
        }}
      >
        {renderAsPortal ? (
          <Link
            href={href}
            style={{
              textDecoration: 'none',
              position: 'relative',
              color: '#1E242F',
            }}
            onClick={onClick}
          >
            {renderBrandImage(brand)}
          </Link>
        ) : (
          renderBrandImage(brand)
        )}
      </div>
    );
  };

  // ======================= VIEWS
  if (isEmpty(brands)) return null;
  return (
    <FlexBrandGroupBox
      backgroundColor={backgroundColor}
      mobilePadding={mobilePadding}
    >
      {showTitle && (
        <Title fontSize={titleSize}>{title || t('newsByBrand')}</Title>
      )}
      {/* <StyledBrandGroup>
        {brands?.map((brand: any, index: Key) => {
          if ((index as number) > 14) return;
          return (
            <StyledBrandItem key={brand.car_brand_id}>
              <a
                href={`/latest?car_brand=${brand.car_brand_slug}`}
                style={{
                  textDecoration: 'none',
                  position: 'relative',
                  color: '#1E242F',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: 44,
                      height: 44,
                    }}
                  >
                    <StyledImage
                      src={brand.car_brand_image}
                      alt={brand.car_brand_name}
                      width={44}
                      height={44}
                    />
                  </div>
                  <p
                    style={{
                      margin: 0,
                      marginTop: 5,
                      fontSize: 12,
                      lineHeight: '15.6px',
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      letterSpacing: '-0.25px',
                      textDecoration: 'none',
                      textAlign: 'center',
                    }}
                  >
                    {brand.car_brand_name}
                  </p>
                </div>
              </a>
            </StyledBrandItem>
          );
        })}
      </StyledBrandGroup> */}
      <FlexBrandGroupWrapper>
        {brands?.map((brand: any, index: Key) => {
          if ((index as number) > 14) return;
          return renderBrandItem(brand);
        })}
      </FlexBrandGroupWrapper>
      <FlexMobileBrandGroupWrapper>
        {brands?.map((brand: any, index: Key) => {
          if ((index as number) > 15) return;
          return renderBrandItem(brand);
        })}
      </FlexMobileBrandGroupWrapper>
    </FlexBrandGroupBox>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default BrandGroup;
